import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["typeSelect", "idSelect"]

  connect() {
    console.log("PolymorphicSelectController connected");
    this.initializeOptions();
  }

  initializeOptions() {
    const selectedType = this.typeSelectTarget.value;
    if (selectedType && !this.isDefaultOption(selectedType)) {
      const idSelect = this.idSelectTarget;
      idSelect.disabled = false;
      this.fetchDataAndUpdateOptions(this.constructEndpoint(selectedType), idSelect, true);
    }
  }

  updateOptions(event) {
    console.log("updateOptions triggered");
    const selectedType = event.target.value;
    const idSelect = this.idSelectTarget;
    idSelect.innerHTML = '';

    if (this.isDefaultOption(selectedType)) {
      idSelect.disabled = true;
    } else {
      idSelect.disabled = false;
      const endpoint = this.constructEndpoint(selectedType);
      this.fetchDataAndUpdateOptions(endpoint, idSelect);
    }
  }

  isDefaultOption(selectedType) {
    return selectedType.startsWith('Please Choose a Type');
  }

  constructEndpoint(selectedType) {
    const context = this.element.getAttribute('data-context');
    let endpointBase = '';

    switch (context) {
      case 'sales':
        endpointBase = `/sales`;
        break;
      case 'overheads':
        endpointBase = `/overheads`;
        break;
      default:
        console.error("Unknown context for endpoint construction");
        return '';
    }

    return `${endpointBase}.json`;
  }

  convertToEndpoint(type) {
    switch (type) {
      case 'Instrument':
        return 'instruments';
      case 'Fitting':
        return 'fittings';
      case 'Rippleboard':
        return 'rippleboards';
      default:
        console.error("Unknown type for endpoint conversion");
        return '';
    }
  }

  fetchDataAndUpdateOptions(endpoint, selectElement, isInitialLoad = false) {
    const urlWithParams = `${endpoint}?type=${encodeURIComponent(this.typeSelectTarget.value)}`;

    fetch(urlWithParams)
      .then(response => response.json())
      .then(data => {
        if (!isInitialLoad) {
          while (selectElement.options.length > 0) selectElement.remove(0);
        }

        data.forEach(item => {
          let optionText = item.name;
          let optionValue = item.id;
          let option = new Option(optionText, optionValue, false, optionValue == selectElement.dataset.preselectedId);
          selectElement.add(option);
        });

        if (isInitialLoad && selectElement.dataset.preselectedId) {
          selectElement.value = selectElement.dataset.preselectedId;
        }

        selectElement.disabled = data.length === 0;
      })
      .catch(error => {
        console.error("Failed to fetch data:", error);
        selectElement.disabled = true;
      });
  }
}
