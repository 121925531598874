import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["display", "startButton", "stopButton"]

  connect() {
    console.log("Instrument Materials controller connected");

    // Check local storage for any running timers
    this.startButtonTargets.forEach((button) => {
      const modelId = button.dataset.modelId;
      const storedStartTime = localStorage.getItem(`timerStart_${modelId}`);

      if (storedStartTime) {
        const startTime = new Date(parseInt(storedStartTime, 10));
        const currentTime = new Date();
        const elapsedTime = currentTime - startTime;

        // Restart the client-side timer
        this.startClientTimer(modelId, startTime);
        this.updateTimerDisplay(modelId, elapsedTime);

        // Disable the start button and enable the stop button
        button.disabled = true;
        this.element.querySelector(`[data-model-id="${modelId}"][data-action="click->timer#stop"]`).disabled = false;
      }
    });
  }

  initialize() {
    this.timerIntervals = {};
  }

  start(event) {
    const button = this.startButtonTargets.find(btn => btn.dataset.modelId === event.target.dataset.modelId);
    const modelName = button.dataset.modelName; // e.g., 'instrument_step'
    const modelId = button.dataset.modelId;

    if (button) {
      button.disabled = true;
    } else {
      console.error('Button not found.');
    }

    Rails.ajax({
      type: "PATCH",
      url: `/${modelName}s/${modelId}/start_timer`,
      success: (response, status, xhr) => {
        console.log("Timer started:", xhr.response);
        // Re-enable the stop button
        this.element.querySelector(`[data-model-id="${modelId}"][data-action="click->timer#stop"]`).disabled = false;
      },
      error: (err) => {
        console.error(err);
        // Re-enable the start button if there's an error
        button.disabled = false;
      }
    });

    // Start the client-side timer
    this.startClientTimer(modelId);
  }

  stop(event) {
    const button = this.stopButtonTargets.find(btn => btn.dataset.modelId === event.target.dataset.modelId);
    const modelName = button.dataset.modelName;
    const modelId = button.dataset.modelId;

    if (button) {
      button.disabled = true;
    } else {
      console.error('Button not found.');
    }

    Rails.ajax({
      type: "PATCH",
      url: `/${modelName}s/${modelId}/stop_timer`,
      success: (response, status, xhr) => {
        // Parse the JSON response from the server
        const data = JSON.parse(xhr.response);
        console.log("Server response:", data);
        console.log("Timer stopped:", data);

        // Clear the current timer display
        this.clearTimerDisplay(modelId);
        // Update the UI with the new time_taken
        this.updateTimeTakenDisplay(modelId, data.time_taken);
        // Stop the client-side timer
        this.stopClientTimer(modelId);

        // Re-enable the start button
        this.element.querySelector(`[data-model-id="${modelId}"][data-action="click->timer#start"]`).disabled = false;
      },
      error: (err) => {
        console.error(err);
        // Re-enable the stop button if there's an error
        button.disabled = false;
      }
    });
  }

  startClientTimer(modelId, startTime = new Date()) {
    // Initialize the timer if it's not set
    if (!this.timerIntervals[modelId]) {
      this.timerIntervals[modelId] = {
        startTime: startTime,
        timer: setInterval(() => {
          const elapsedTime = new Date() - this.timerIntervals[modelId].startTime;
          this.updateTimerDisplay(modelId, elapsedTime);
        }, 1000) // Update the timer every second
      };
    }

    // Store start time in local storage
    localStorage.setItem(`timerStart_${modelId}`, this.timerIntervals[modelId].startTime.getTime().toString());
  }

  stopClientTimer(modelId) {
    // Clear the interval and delete the timer
    if (this.timerIntervals[modelId]) {
      clearInterval(this.timerIntervals[modelId].timer);
      delete this.timerIntervals[modelId];

      // Remove start time from local storage
      localStorage.removeItem(`timerStart_${modelId}`);
    }
  }

  updateTimerDisplay(modelId, elapsedTime) {
    const liveDisplayElement = this.element.querySelector(`[data-model-id="${modelId}"][data-timer-target="liveDisplay"]`);
    if (liveDisplayElement) {
      const timeString = this.formatTime(elapsedTime);
      liveDisplayElement.textContent = timeString;
    } else {
      console.error('Live display element not found for modelId:', modelId);
    }
  }

  clearTimerDisplay(modelId) {
    const liveDisplayElement = this.element.querySelector(`[data-model-id="${modelId}"][data-timer-target="liveDisplay"]`);
    if (liveDisplayElement) {
      liveDisplayElement.textContent = '00:00:00'; // Clear the live timer display
    } else {
      console.error('Live display element not found for modelId:', modelId);
    }
  }

  updateTimeTakenDisplay(modelId, timeTaken) {
    const timeTakenElement = this.element.querySelector(`[data-model-id="${modelId}"][data-timer-target="timeTaken"]`);
    if (timeTakenElement) {
      // Convert time_taken (in seconds) to HH:MM:SS format
      const formattedTime = this.formatTime(timeTaken * 1000); // Multiply by 1000 because formatTime expects milliseconds
      timeTakenElement.textContent = formattedTime;
    } else {
      console.error('Time taken display element not found for modelId:', modelId);
    }
  }

  formatTime(elapsedTime) {
    // Convert milliseconds into hours:minutes:seconds
    let seconds = Math.floor((elapsedTime / 1000) % 60),
      minutes = Math.floor((elapsedTime / (1000 * 60)) % 60),
      hours = Math.floor((elapsedTime / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return `${hours}:${minutes}:${seconds}`;
  }
}
