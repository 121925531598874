// app/javascript/controllers/order_number_controller.js

import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["category", "order"]

  connect() {
    console.log("OrderNumber controller connected");
  }

  updateOrder() {
    const category = this.categoryTarget.value;
    if(category === "") return; // Exit if no category selected

    const url = `/steps/next_order_number?category=${category}`;
    Rails.ajax({
      type: "GET",
      url: url,
      success: (data) => {
        this.orderTarget.value = data.next_order_number;
      },
      error: (error) => {
        console.log(error);
      },
    });
  }
}
