// theme_switcher_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["lightIcon", "darkIcon"]

  connect() {
    this.authenticatedValue = document.querySelector('meta[name="user-status"]').content === 'authenticated';

    if (!this.authenticatedValue) {
      console.log('User is not authenticated. ThemeSwitcher will not activate.');
      return;
    }

    console.log('ThemeSwitcher controller connected');
    this.updateTheme();
    this.updateIcons();
  }

  updateTheme() {
    this.currentTheme = localStorage.getItem('theme') || 'light';
    document.documentElement.classList.toggle('dark', this.currentTheme === 'dark');
    this.element.setAttribute('data-theme', this.currentTheme);
  }

  toggle() {
    this.currentTheme = this.currentTheme === 'light' ? 'dark' : 'light';
    document.documentElement.classList.toggle('dark', this.currentTheme === 'dark');
    this.element.setAttribute('data-theme', this.currentTheme);
    localStorage.setItem('theme', this.currentTheme);
    this.updateIcons();
  }

  updateIcons() {
    console.log(`Updating icons. Current theme: ${this.currentTheme}`);
    if (this.currentTheme === 'light') {
      this.lightIconTarget.classList.remove('hidden');
      this.darkIconTarget.classList.add('hidden');
    } else {
      this.lightIconTarget.classList.add('hidden');
      this.darkIconTarget.classList.remove('hidden');
    }
  }
}
