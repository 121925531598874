// app/javascript/controllers/instrument_form_controller.js

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["type", "rippleAmount"]

  connect() {
    this.updateRipplePlaceholder() // To set the initial value on page load
  }

  updateRipplePlaceholder() {
    const instrumentTypeId = this.typeTarget.value;
    let placeholderText = '';
    // Placeholder logic here. You can make this dynamic by fetching data from the server if needed.
    switch (instrumentTypeId) {
      case '0':
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
        placeholderText = '29 for violin family instruments.';
        break;
      case '8':
      case '9':
      case '10':
        placeholderText = '21+ for guitar family instruments.';
        break;
      // Add more cases or logic as needed
      default:
        placeholderText = 'Enter the amount of Ripples';
    }
    this.rippleAmountTarget.placeholder = placeholderText;
  }
}
