import { Controller } from "@hotwired/stimulus"
import { create as createFilePond, registerPlugin } from 'filepond';
import "filepond/dist/filepond.min.css"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  static values = {
    model: String,
    modelId: Number,
    attachUrl: String
  }

  connect() {
    registerPlugin(FilePondPluginImagePreview);

    const fileInput = this.element.querySelector('input[type="file"]');

    this.pond = createFilePond(fileInput, {
      credits: false,
      onprocessfile: (error, fileItem) => {
        if (!error) {
          this.appendImageToGallery(fileItem.file);
        }
      },
      server: {
        process: (fieldName, file, metadata, load, error, progress, abort) => {
          const upload = new DirectUpload(file, "/rails/active_storage/direct_uploads", this)
          upload.create((error, blob) => {
            if (error) {
              error(error);
            } else {
              // Immediately send the signed_id to the server for attachment
              this.sendSignedId(blob.signed_id).then(response => {
                // Assuming the server responds with the URL for the uploaded image
                if (response.message) {
                  load(response.message);
                  // this.appendImageToGalleryDirectly(response.url);
                }
              }).catch(uploadError => {
                error(uploadError);
              });
            }
          });

          return {
            abort: () => {
              abort();
            }
          };
        }
      }
    });
  }

  deleteImage(event) {
    event.preventDefault();

    if (!confirm("Are you sure you want to delete this photo?")) {
      return;
    }

    const imageId = event.currentTarget.dataset.imageId;
    const model = event.currentTarget.dataset.model;
    const modelId = event.currentTarget.dataset.modelId;
    const url = `/${model}/${modelId}/delete_image/${imageId}`; // Adjust URL pattern based on your routing

    fetch(url, {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").getAttribute("content"),
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.ok) {
          // Remove the carousel item from the DOM
          document.getElementById(`image-${imageId}`).remove();
        } else {
          // Handle error (e.g., show an error message)
          console.error("Failed to delete image");
        }
      })
      .catch(error => {
        console.error("Error:", error);
      });
  }

  async sendSignedId(signedId) {
    const attachUrl = this.attachUrlValue; // This should already be the correct URL
    try {
      const response = await fetch(attachUrl, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").getAttribute("content"),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          image_signed_ids: [signedId]
        })
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error("Failed to send signed ID:", error);
    }
  }

  appendImageToGallery(file) {
    const reader = new FileReader();
    reader.onload = (event) => {
      const imageUrl = event.target.result;
      this.appendImageToCarousel(imageUrl);
    };
    reader.readAsDataURL(file);
  }

  appendImageToCarousel(imageUrl) {
    const carousel = document.querySelector('.carousel');
    const newItem = document.createElement('div');
    newItem.className = 'carousel-item';
    newItem.innerHTML = `<img src="${imageUrl}" alt="Uploaded Image" class="gallery-image w-full h-full object-cover rounded-md" style="max-width: 800px; max-height: 600px;" />`;
    carousel.appendChild(newItem);
  }

  appendImageToGalleryDirectly(imageUrl) {
    this.appendImageToCarousel(imageUrl);
  }
}
