import "@hotwired/turbo-rails"
import "@hotwired/stimulus"

import "trix"
import "@rails/actiontext"

const theme = localStorage.getItem('theme') || 'light';
document.querySelector('[data-controller="theme-switcher"]').setAttribute('data-theme', theme);

import "../controllers"
