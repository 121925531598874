import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["select"];
  static values = { modelName: String, modelId: Number };

  connect() {
    this.updateSelectClass(this.selectTarget.value); // Initial class update
  }

  changeStatus() {
    const status = this.selectTarget.value;
    const modelName = this.modelNameValue;
    const modelId = this.modelIdValue;

    Rails.ajax({
      type: "PATCH",
      url: `/${modelName}s/${modelId}/update_status`,
      dataType: "json",
      data: `status=${status}`,
      success: (data) => {
        console.log("Status updated successfully", data);
        this.updateSelectClass(status);
      },
      error: (error) => {
        console.error("Error updating status:", error);
      },
    });
  }

  updateSelectClass(status) {
    const select = this.selectTarget;
    const colors = this.statusColorClasses(status);
    // Clear existing color classes
    select.classList.remove("bg-red-700", "border-red-900", "bg-blue-700", "border-blue-900", "bg-orange-700", "border-orange-900", "bg-green-700", "border-green-900");
    // Add new color classes based on status
    select.classList.add(colors.bg, colors.border);
  }

  statusColorClasses(status) {
    const colorMapping = {
      "0": { bg: "bg-red-700", border: "border-red-900" }, // Adjust these values based on your actual status-color mappings
      "1": { bg: "bg-blue-700", border: "border-blue-900" },
      "2": { bg: "bg-orange-700", border: "border-orange-900" },
      "3": { bg: "bg-green-700", border: "border-green-900" },
      "4": { bg: "bg-gray-700", border: "border-gray-900" }
    };
    return colorMapping[status] || { bg: "", border: "" }; // Default to no additional classes if status is unknown
  }
}
