// app/javascript/controllers/fitting_materials_controller.js
import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["list", "form"];

  connect() {
    console.log("Fitting Materials controller connected");
  }

  submitForm(event) {
    event.preventDefault();
    const formData = new FormData(this.formTarget);

    Rails.ajax({
      url: this.formTarget.action,
      type: "POST",
      data: formData,
      dataType: "json",
      success: (response, status, xhr) => {
        this.fittingMaterialAdded(response, status, xhr);
      },
      error: (err) => {
        this.materialError(err);
      },
    });
  }

  fittingMaterialAdded(data, status, xhr) {
    console.log("Fitting Material added", data);

    if (xhr.status === 201 || xhr.status === 200) {
      const fittingMaterial = data;
      this.listTarget.insertAdjacentHTML(
        "beforeend",
        this.fittingMaterialTemplate(fittingMaterial)
      );
      this.formTarget.reset();
    } else {
      console.error("Unexpected status code:", status);
    }
  }

  materialError(err) {
    console.error("Submission error", err);
  }

  fittingMaterialTemplate(fittingMaterial) {
    const fittingMaterialId = fittingMaterial.id;
    const materialName = fittingMaterial.material.name;
    const quantityUsed = fittingMaterial.quantity_used;
    const cost = fittingMaterial.cost;

    return `
      <div class="flex justify-between items-center py-2" id="fitting_material_${fittingMaterialId}">
        <p><a class="text-blue-500" href="/materials/${fittingMaterialId}">${materialName}</a> - Quantity: ${this.formatNumberWithPrecision(quantityUsed)}, Cost: $${this.formatNumberWithPrecision(cost)}</p>
        <button data-action="click->fitting-materials#removeFittingMaterial" data-turbo="false" data-material-id="${fittingMaterialId}" class="rounded-md bg-red-700 py-2 px-4 flex text-xs items-center justify-center text-white">Remove</button>
      </div>
    `;
  }

  removeFittingMaterial(event) {
    event.preventDefault();

    if (!confirm("Are you sure you want to delete this material?")) {
      return;
    }

    const materialId = event.currentTarget.dataset.materialId;

    Rails.ajax({
      url: `/fitting_materials/${materialId}`,
      type: "DELETE",
      dataType: "json",
      success: (data, status, xhr) => {
        if (xhr.status === 204 || xhr.status === 200) {
          const materialElement = document.getElementById(
            `fitting_material_${materialId}`
          );
          if (materialElement) {
            this.listTarget.removeChild(materialElement);
            console.log(`Material ${materialId} removed successfully.`);
            console.log("Server message:", data.success);
          } else {
            console.error(
              `Element with ID fitting_material_${materialId} not found.`
            );
          }
        } else {
          console.error("Error removing material:", data.error || status);
        }
      },
      error: (err) => {
        console.error("AJAX request error when removing material:", err);
      },
    });
  }

  formatNumberWithPrecision(value, precision = 2) {
    const formattedNumber = Number(value).toFixed(precision);

    return Number(formattedNumber).toLocaleString("en-US", {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    });
  }
}
